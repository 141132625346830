import React from "react";

import img from "../../assets/images/111.jpg";
import img2 from "../../assets/images/113.jpg";
import img3 from "../../assets/images/111.jpg";

import styles from "./MainPage.module.css";
import "swiper/css";

import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

const MainPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className={styles.wrapper}>
        <div>{}</div>
        <div className={styles.navigator}>
          <Link>Календари</Link>
          <Link>Пакет.</Link>
          <Link>Коробки</Link>
          <Link>Печать</Link>
          <Link>Контакты</Link>
          <Link>О нас</Link>
          <Link>Сувениры</Link>
        </div>
        {/* <div className={styles.intro}></div> */}
        <div className={styles.list}>
          <Link to={"product/1"}>
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              className={styles.swiper}
            >
              <SwiperSlide>
                <Link
                  className={styles.cart}
                  // to={"http://000000000000.ru/fond/1"}
                >
                  <img src={img} alt="" />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img} alt="" />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img2} alt="" />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img3} alt="" />
                </Link>
              </SwiperSlide>
            </Swiper>
            <div className={styles.text}>
              <a
                href={
                  "https://www.ozon.ru/product/bumaga-dlya-risovaniya-a4-21-29-7-sm-2-list-sht-1526997244/"
                }
              >
                Ozon1
              </a>
            </div>
          </Link>
          <Link to={"product/1"}>
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              className={styles.swiper}
            >
              <SwiperSlide>
                <Link
                  className={styles.cart}
                  // to={"http://000000000000.ru/fond/1"}
                >
                  <img src={img} alt="" />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img} alt="" />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img2} alt="" />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img3} alt="" />
                </Link>
              </SwiperSlide>
            </Swiper>
            <div className={styles.text}>
              <a
                href={
                  "https://www.ozon.ru/product/bumaga-dlya-risovaniya-a4-21-29-7-sm-2-list-sht-1526997244/"
                }
              >
                Ozon2
              </a>
            </div>
          </Link>{" "}
          <Link to={"product/1"}>
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              className={styles.swiper}
            >
              <SwiperSlide>
                <Link
                  className={styles.cart}
                  // to={"http://000000000000.ru/fond/1"}
                >
                  <img src={img} alt="" />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img} alt="" />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img2} alt="" />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img3} alt="" />
                </Link>
              </SwiperSlide>
            </Swiper>
            <div className={styles.text}>
              <a
                href={
                  "https://www.ozon.ru/product/bumaga-dlya-risovaniya-a4-21-29-7-sm-2-list-sht-1526997244/"
                }
              >
                Ozon3
              </a>
            </div>
          </Link>{" "}
          <Link to={"product/1"}>
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              className={styles.swiper}
            >
              <SwiperSlide>
                <Link
                  className={styles.cart}
                  // to={"http://000000000000.ru/fond/1"}
                >
                  <img src={img} alt="" />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img} alt="" />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img2} alt="" />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img3} alt="" />
                </Link>
              </SwiperSlide>
            </Swiper>
            <div className={styles.text}>
              <a
                href={
                  "https://www.ozon.ru/product/bumaga-dlya-risovaniya-a4-21-29-7-sm-2-list-sht-1526997244/"
                }
              >
                Ozon4
              </a>
            </div>
          </Link>{" "}
          <Link to={"product/1"}>
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              className={styles.swiper}
            >
              <SwiperSlide>
                <Link
                  className={styles.cart}
                  // to={"http://000000000000.ru/fond/1"}
                >
                  <img src={img} alt="" />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img} alt="" />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img2} alt="" />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img3} alt="" />
                </Link>
              </SwiperSlide>
            </Swiper>
            <div className={styles.text}>
              <a
                href={
                  "https://www.ozon.ru/product/bumaga-dlya-risovaniya-a4-21-29-7-sm-2-list-sht-1526997244/"
                }
              >
                Ozon5
              </a>
            </div>
          </Link>
          <Link to={"product/1"}>
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              className={styles.swiper}
            >
              <SwiperSlide>
                <Link
                  className={styles.cart}
                  // to={"http://000000000000.ru/fond/1"}
                >
                  <img src={img} alt="" />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img} alt="" />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img2} alt="" />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img3} alt="" />
                </Link>
              </SwiperSlide>
            </Swiper>
            <div className={styles.text}>
              <a
                href={
                  "https://www.ozon.ru/product/bumaga-dlya-risovaniya-a4-21-29-7-sm-2-list-sht-1526997244/"
                }
              >
                Ozon6
              </a>
            </div>
          </Link>
          <Link to={"product/1"}>
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              className={styles.swiper}
            >
              <SwiperSlide>
                <Link
                  className={styles.cart}
                  // to={"http://000000000000.ru/fond/1"}
                >
                  <img src={img} alt="" />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img} alt="" />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img2} alt="" />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img3} alt="" />
                </Link>
              </SwiperSlide>
            </Swiper>
            <div className={styles.text}>
              <a
                href={
                  "https://www.ozon.ru/product/bumaga-dlya-risovaniya-a4-21-29-7-sm-2-list-sht-1526997244/"
                }
              >
                Ozon7
              </a>
            </div>
          </Link>
          <Link to={"product/1"}>
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              className={styles.swiper}
            >
              <SwiperSlide>
                <Link
                  className={styles.cart}
                  // to={"http://000000000000.ru/fond/1"}
                >
                  <img src={img} alt="" />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img} alt="" />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img2} alt="" />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img3} alt="" />
                </Link>
              </SwiperSlide>
            </Swiper>
            <div className={styles.text}>
              <a
                href={
                  "https://www.ozon.ru/product/bumaga-dlya-risovaniya-a4-21-29-7-sm-2-list-sht-1526997244/"
                }
              >
                Ozon8
              </a>
            </div>
          </Link>
          <Link to={"product/1"}>
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              className={styles.swiper}
            >
              <SwiperSlide>
                <Link
                  className={styles.cart}
                  // to={"http://000000000000.ru/fond/1"}
                >
                  <img src={img} alt="" />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img} alt="" />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img2} alt="" />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img3} alt="" />
                </Link>
              </SwiperSlide>
            </Swiper>
            <div className={styles.text}>
              <a
                href={
                  "https://www.ozon.ru/product/bumaga-dlya-risovaniya-a4-21-29-7-sm-2-list-sht-1526997244/"
                }
              >
                Ozon9
              </a>
            </div>
          </Link>
          <Link to={"product/1"}>
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              className={styles.swiper}
            >
              <SwiperSlide>
                <Link
                  className={styles.cart}
                  // to={"http://000000000000.ru/fond/1"}
                >
                  <img src={img} alt="" />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img} alt="" />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img2} alt="" />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img3} alt="" />
                </Link>
              </SwiperSlide>
            </Swiper>
            <div className={styles.text}>
              <a
                href={
                  "https://www.ozon.ru/product/bumaga-dlya-risovaniya-a4-21-29-7-sm-2-list-sht-1526997244/"
                }
              >
                Ozon10
              </a>
            </div>
          </Link>
          <Link to={"product/1"}>
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              className={styles.swiper}
            >
              <SwiperSlide>
                <Link
                  className={styles.cart}
                  // to={"http://000000000000.ru/fond/1"}
                >
                  <img src={img} alt="" />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img} alt="" />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img2} alt="" />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img3} alt="" />
                </Link>
              </SwiperSlide>
            </Swiper>
            <div className={styles.text}>
              <a
                href={
                  "https://www.ozon.ru/product/bumaga-dlya-risovaniya-a4-21-29-7-sm-2-list-sht-1526997244/"
                }
              >
                Ozon11
              </a>
            </div>
          </Link>
          <Link to={"product/1"}>
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              className={styles.swiper}
            >
              <SwiperSlide>
                <Link
                  className={styles.cart}
                  // to={"http://000000000000.ru/fond/1"}
                >
                  <img src={img} alt="" />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img} alt="" />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img2} alt="" />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img3} alt="" />
                </Link>
              </SwiperSlide>
            </Swiper>
            <div className={styles.text}>
              <a
                href={
                  "https://www.ozon.ru/product/bumaga-dlya-risovaniya-a4-21-29-7-sm-2-list-sht-1526997244/"
                }
              >
                Ozon12
              </a>
            </div>
          </Link>
          <Link to={"product/1"}>
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              className={styles.swiper}
            >
              <SwiperSlide>
                <Link
                  className={styles.cart}
                  // to={"http://000000000000.ru/fond/1"}
                >
                  <img src={img} alt="" />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img} alt="" />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img2} alt="" />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img3} alt="" />
                </Link>
              </SwiperSlide>
            </Swiper>
            <div className={styles.text}>
              <a
                href={
                  "https://www.ozon.ru/product/bumaga-dlya-risovaniya-a4-21-29-7-sm-2-list-sht-1526997244/"
                }
              >
                Ozon13
              </a>
            </div>
          </Link>
          <Link to={"product/1"}>
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              className={styles.swiper}
            >
              <SwiperSlide>
                <Link
                  className={styles.cart}
                  // to={"http://000000000000.ru/fond/1"}
                >
                  <img src={img} alt="" />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img} alt="" />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img2} alt="" />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img3} alt="" />
                </Link>
              </SwiperSlide>
            </Swiper>
            <div className={styles.text}>
              <a
                href={
                  "https://www.ozon.ru/product/bumaga-dlya-risovaniya-a4-21-29-7-sm-2-list-sht-1526997244/"
                }
              >
                Ozon14
              </a>
            </div>
          </Link>
          <Link to={"product/1"}>
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              className={styles.swiper}
            >
              <SwiperSlide>
                <Link
                  className={styles.cart}
                  // to={"http://000000000000.ru/fond/1"}
                >
                  <img src={img} alt="" />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img} alt="" />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img2} alt="" />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img3} alt="" />
                </Link>
              </SwiperSlide>
            </Swiper>
            <div className={styles.text}>
              <a
                href={
                  "https://www.ozon.ru/product/bumaga-dlya-risovaniya-a4-21-29-7-sm-2-list-sht-1526997244/"
                }
              >
                Ozon15
              </a>
            </div>
          </Link>
        </div>
        <div className={styles.footer}></div>
      </div>

      <div>ООО "Свет"</div>
    </>
  );
};

export default MainPage;
